/* Fjalla One */
@font-face {
    font-family: 'Fjalla One';
    src: url('assets/fonts/FjallaOne/FjallaOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Cabin Condensed */
@font-face {
    font-family: 'Cabin Condensed';
    src: url('assets/fonts/CabinCondensed/CabinCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabin Condensed';
    src: url('assets/fonts/CabinCondensed/CabinCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabin Condensed';
    src: url('assets/fonts/CabinCondensed/CabinCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabin Condensed';
    src: url('assets/fonts/CabinCondensed/CabinCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
